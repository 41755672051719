import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, ChevronRight } from 'lucide-react';
import About from './About';
import Contact from './Contact';
import Header from './Header';

const apps = [
  {
    id: 1,
    name: 'GoalQuest',
    description: 'Transform your goals into an engaging, gamified experience. Share progress, compete with friends, and stay motivated!',
    image: '/images/goalquest-screenshot.png',
    link: 'https://goalquest.life'
  },
  {
    id: 2,
    name: 'FlashMaster',
    description: 'Master your learning with the ultimate tool to create, study, and manage flashcards with ease.',
    image: '/images/flashmaster-screenshot.png',
    link: 'https://flash-master.com'
  }
];

const AppShowcase = () => {
  const [activeApp, setActiveApp] = useState(null);
  const [currentPage, setCurrentPage] = useState('home');

  const handleNavigation = (page) => {
    setCurrentPage(page);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'about':
        return <About />;
      case 'contact':
        return <Contact />;
      default:
        return (
          <main>
            <motion.h1 
              className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Featured Applications
            </motion.h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {apps.map((app) => (
                <motion.div
                  key={app.id}
                  className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-xl overflow-hidden shadow-lg border border-gray-200 cursor-pointer"
                  whileHover={{ y: -5, boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
                  onClick={() => setActiveApp(app)}
                >
                  <img src={app.image} alt={app.name} className="w-full h-48 object-cover" />
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">{app.name}</h3>
                    <p className="text-gray-600 mb-4">{app.description}</p>
                    <div className="flex items-center text-blue-600 font-semibold">
                      Learn More <ChevronRight className="ml-1 h-4 w-4" />
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </main>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 text-gray-800">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiNGMEYwRjAiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-75"></div>
      <div className="relative min-h-screen">
        <div className="max-w-6xl mx-auto p-4 sm:p-6 md:p-8">
          <Header currentPage={currentPage} onNavigate={handleNavigation} />
          {renderPage()}
          
          <AnimatePresence>
            {activeApp && (
              <motion.div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 backdrop-filter backdrop-blur-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setActiveApp(null)}
              >
                <motion.div
                  className="bg-white rounded-xl p-4 sm:p-6 md:p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto"
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 50, opacity: 0 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-xl sm:text-2xl font-bold mb-4">{activeApp.name}</h3>
                  {activeApp.name === 'GoalQuest' ? (
                    <div className="flex flex-col md:flex-row gap-4 md:gap-8">
                      <div className="md:w-1/2">
                        <img src={activeApp.image} alt={activeApp.name} className="w-full rounded-lg shadow-lg" />
                      </div>
                      <div className="md:w-1/2">
                        <p className="text-gray-600 mb-4 sm:mb-6">{activeApp.description}</p>
                        <h4 className="text-lg font-semibold mb-2">Key Features:</h4>
                        <ul className="list-disc list-inside mb-4 sm:mb-6">
                          <li>Progress Tracking</li>
                          <li>Share Your Achievements</li>
                          <li>Leaderboards & Friendly Competition</li>
                          <li>Create Custom Tasks</li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <>
                      <img src={activeApp.image} alt={activeApp.name} className="w-full h-48 sm:h-64 object-cover rounded-lg mb-4 sm:mb-6" />
                      <p className="text-gray-600 mb-4 sm:mb-6">{activeApp.description}</p>
                      <h4 className="text-lg font-semibold mb-2">Key Features:</h4>
                      <ul className="list-disc list-inside mb-4 sm:mb-6">
                        <li>Custom Flashcard Creation</li>
                        <li>Flexible Study Modes</li>
                        <li>Organize & Manage Flashcards</li>
                        <li>Progress Tracking</li>
                      </ul>
                    </>
                  )}
                  <a
                    href={activeApp.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Open Application <ExternalLink className="ml-2 h-4 w-4" />
                  </a>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AppShowcase;
